import { DownOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  MenuProps,
  Row,
  Space,
} from 'antd';
import { useEffect } from 'react';
import { RiOrganizationChart } from 'react-icons/ri';
import AzureUserList from '../form/AzureUserList';
import {
  formItemLayoutFull,
  WorkspaceEditChildModalProps,
} from './WorkspaceEditModal';

const items: MenuProps['items'] = [
  {
    label: 'Share with selected colleagues',
    key: '1',
    icon: <UsergroupAddOutlined />,
  },
  {
    label: 'Share with everyone at OMMAX',
    key: '2',
    icon: <RiOrganizationChart />,
    disabled: true,
  },
];

const WorkspaceShare: React.FC<WorkspaceEditChildModalProps> = (params) => {
  //const form = Form.useFormInstance();
  const { form, current } = params;
  const isPublic = Form.useWatch('isPublic', form);

  console.log(isPublic);

  useEffect(() => {
    console.log(isPublic);
  }, [isPublic]);

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col flex="auto"></Col>
        <Col flex="none">
          <Dropdown
            menu={{
              items,
              onClick: (item) => {
                console.log({ isPublic: item.key === '2' ? 1 : 0 });
                form.setFieldsValue({ isPublic: item.key === '2' ? 1 : 0 });
              },
              selectedKeys: [isPublic ? '2' : '1'],
            }}
          >
            <Button>
              <Space>
                {isPublic
                  ? 'Shared with everyone at OMMAX'
                  : 'Shared with selected colleagues'}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>

      {isPublic ? (
        <Alert message="Informational Notes" type="info" showIcon />
      ) : (
        <>
          {!current && (
            <Alert
              message="You will be automatically granted access to this workspace as owner after creation"
              type="info"
              showIcon
              style={{ marginBottom: 10 }}
            />
          )}
          <Form.Item name="shares" {...formItemLayoutFull}>
            <AzureUserList showAddBar />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default WorkspaceShare;

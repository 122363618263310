import { DownOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  List,
  MenuProps,
  message,
  Row,
  Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { FaAws } from 'react-icons/fa6';
import { SiConfluence, SiMicrosoftsharepoint } from 'react-icons/si';
import { MdAttachFile } from 'react-icons/md';
import { FileSource } from '../../data/fileSource';
import FileSourceConfluenceModal from '../fileSource/FileSourceConfluenceModal';
import FileSourceS3Modal from '../fileSource/FileSourceS3Modal';
import FileSourceSharepointModal from '../fileSource/FileSourceSharepointModal';
import FileSourceFileUploadModal from '../fileSource/FileSourceFileUploadModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const FILE_SOURCE_NAMES: any = {
  confluence: 'Confluence',
  sharepoint: 'SharePoint',
  s3: 'AWS S3 Bucket',
  fileUpload: 'File Upload',
};

const FileSourceList: React.FC<any> = (props) => {
  const {
    placeholder = 'Type to search user',
    showAddBar = false,
    onSave = undefined,
    listOnly = false,
    ...otherProps
  } = props;
  //const [fileSources, setFileSources] = useState<FileSource[]>([]);
  const fileSources = props.value;
  const setFileSources = props.onChange;
  const [current, setCurrent] = useState<FileSource>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isConfluenceModalOpen, setIsConfluenceModalOpen] =
    useState<boolean>(false);
  const [isS3ModalOpen, setIsS3ModalOpen] = useState<boolean>(false);
  const [isSharepointModalOpen, setIsSharepointModalOpen] =
    useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  const items: MenuProps['items'] = [
    {
      label: FILE_SOURCE_NAMES['confluence'],
      key: 'confluence',
      icon: <SiConfluence />,
    },
    {
      label: FILE_SOURCE_NAMES['s3'],
      key: 's3',
      icon: <FaAws />,
      disabled: !isAdmin,
    },
    {
      label: FILE_SOURCE_NAMES['sharepoint'],
      key: 'sharepoint',
      icon: <SiMicrosoftsharepoint />,
    },
  ];

  useEffect(() => {
    if (!isConfluenceModalOpen && !isS3ModalOpen) setCurrent(undefined);
  }, [isConfluenceModalOpen, isS3ModalOpen]);

  const handleUpdateOrAdd = (source: Partial<FileSource>) => {
    if (current) {
      setFileSources(
        fileSources.map((s: any) =>
          current._id === s._id ? { ...s, ...source } : s,
        ),
      );
    } else {
      // @ts-ignore
      setFileSources([source, ...fileSources]);
    }
  };

  const remove = (item: FileSource, index: number) => {
    // if file source doesn't have _id attribute it was just created in frontend, so we can remove from list
    if (!item._id) {
      const newFileSource = [...fileSources];
      newFileSource.splice(index, 1);
      console.log(newFileSource);
      setFileSources(newFileSource);
    }
    // existing items in backend will be marked with deleteItem flag (backend performs delete, frontend hides in view)
    else {
      setFileSources(
        fileSources.map((source: any) => ({
          ...source,
          deleteItem: source._id === item._id ? true : source.deleteItem,
        })),
      );
    }
  };

  const openModal = (type: string) => {
    if (type === 'confluence') setIsConfluenceModalOpen(true);
    if (type === 's3') setIsS3ModalOpen(true);
    if (type === 'fileUpload') setIsFileUploadModalOpen(true);
    if (type === 'sharepoint') setIsSharepointModalOpen(true);
  };
  const edit = (item: FileSource) => {
    setCurrent(item);
    openModal(item.type);
  };

  return (
    <>
      {contextHolder}

      <Row style={{ marginBottom: 10 }}>
        <Col flex="auto"></Col>
        <Col flex="none">
          {listOnly !== true && (
            <Dropdown
              menu={{
                items,
                onClick: (item) => {
                  openModal(item.key);
                },
              }}
            >
              <Button type={!!onSave ? 'default' : 'primary'}>
                <Space>
                  New connection
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </Col>
      </Row>

      {!listOnly && (
        <Alert
          type="info"
          showIcon
          message="You can upload individual files using the Files-Menu of the workspace"
        ></Alert>
      )}
      <List<FileSource>
        itemLayout="horizontal"
        dataSource={fileSources?.filter((source: any) => !source.deleteItem)}
        renderItem={(item, index) => (
          <>
            {true && (
              <List.Item
                actions={
                  listOnly || (item.type === 's3' && !isAdmin)
                    ? undefined
                    : [
                        <Button type="link" onClick={() => edit(item)}>
                          edit
                        </Button>,
                        <Button
                          danger
                          type="link"
                          onClick={() => remove(item, index)}
                        >
                          remove
                        </Button>,
                      ]
                }
              >
                <List.Item.Meta
                  title={FILE_SOURCE_NAMES[item.type]}
                  description={item.humanReadableName || ''}
                />
              </List.Item>
            )}
          </>
        )}
      />
      <FileSourceConfluenceModal
        isOpen={isConfluenceModalOpen}
        setIsOpen={setIsConfluenceModalOpen}
        updateFileSource={(source) => handleUpdateOrAdd(source)}
        current={current}
      />
      <FileSourceS3Modal
        isOpen={isS3ModalOpen}
        setIsOpen={setIsS3ModalOpen}
        updateFileSource={(source) => handleUpdateOrAdd(source)}
        current={current}
      />
      <FileSourceSharepointModal
        isOpen={isSharepointModalOpen}
        setIsOpen={setIsSharepointModalOpen}
        updateFileSource={(source) => handleUpdateOrAdd(source)}
        current={current}
      />
      <FileSourceFileUploadModal
        isOpen={isFileUploadModalOpen}
        setIsOpen={setIsFileUploadModalOpen}
        updateFileSource={(source) => handleUpdateOrAdd(source)}
        current={current}
      />
    </>
  );
};

export default FileSourceList;

import { request_python, request } from '../utils/request';
import { BackendResponse } from './data';
import { RcFile } from 'antd/es/upload/interface';

interface FileStatus {
  count: number;
  fileRefs: string[];
}

export interface SyncStatusResponse {
  done: FileStatus;
  waiting_for_deletion: FileStatus;
  waiting_for_ingestion: FileStatus;
  failed: FileStatus;
}

export const getSyncStatusWorkspace = async (workspaceId: string) => {
  const res = await request<SyncStatusResponse>(
    '/api/v1/sync/workspace/' + workspaceId + '/status',
    {
      method: 'get',
      ...request_python,
    },
  );
  return res;
};

export const syncWorkspace = async (workspaceId: string) => {
  const res = await request<SyncStatusResponse>(
    '/api/v1/sync/workspace/' + workspaceId,
    {
      method: 'get',
      ...request_python,
    },
  );
  return res;
};
